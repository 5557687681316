import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

export function LoadingButton(props: ButtonProps & { isLoading?: boolean }) {
  const { isLoading, ...rest } = props;
  return (
    <Button {...rest}>
      {isLoading ? <CircularProgress size={20} /> : props.children}
    </Button>
  );
}
