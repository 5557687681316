import { createContext, FunctionComponent, useState } from 'react';

var timer: any;

export const NotificationsContext = createContext<INotificationsContextValue>(
  {} as INotificationsContextValue
);

export const NotificationsContextProvider: FunctionComponent<any> = ({
  children,
}) => {
  const [notification, setNotification] = useState<INotification | undefined>();

  function showNotification(payload: INotification, timeout?: number) {
    clearTimeout(timer);

    setNotification(payload);

    timer = setTimeout(() => setNotification(undefined), timeout || 4000);
  }

  const state = {
    notification,
    showNotification,
  };

  return (
    <NotificationsContext.Provider value={state}>
      {children}
    </NotificationsContext.Provider>
  );
};

interface INotificationsContextValue {
  notification: INotification | undefined;
  showNotification: (notification: INotification, timeout?: number) => void;
}

interface INotification {
  type: 'error' | 'success';
  message: string;
}
